<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách khách hàng</h3>
            </div>
            <div class="card-title" v-if="currentUser.user.permission.includes(permission.XEM_DS_KH)">
                <div class="export-google-sheet" v-if="currentUser.user.permission.includes(permission.EXPORT_DSKH)">
                    <div class="my-3">
                        <!-- Our triggering (target) element -->
                        <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2">
                            <i class="la la-file-excel"></i> Xuất google sheet
                        </b-button>
                    </div>
                    <!-- Output from the popover interaction -->
                    <b-popover
                            target="popover-reactive-1"
                            triggers="click"
                            :show.sync="popoverShow"
                            placement="auto"
                            container="my-container"
                            ref="popover"
                            @show="onShow"
                            @hidden="onHidden"
                    >
                        <template #title>
                            <b-button @click="onClose" class="close" aria-label="Close">
                                <span class="d-inline-block" aria-hidden="true">&times;</span>
                            </b-button>
                            Dánh sách thư mục
                        </template>

                        <div>
                            <b-form-group
                                    label-for="popover-input-1"
                                    label-cols="3"
                                    :state="validateState"
                                    class="mb-1"
                                    invalid-feedback="Không được để trống"
                                    size="lg"
                            >
                                <b-form-select
                                        id="popover-input-1"
                                        v-model="folderData"
                                        :state="validateState"
                                        :options="options"
                                        size="sm"
                                        class="mb-3"
                                ></b-form-select>
                            </b-form-group>
                            <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
                            <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
                        </div>
                    </b-popover>
                    <button v-if="is_export_sheet" disabled
                            class="btn btn-primary font-weight-bold mr-2 spinner spinner-white spinner-left">
                        Xuất google sheet
                    </button>
                </div>
                <div v-if="currentUser.user.permission.includes(permission.EXPORT_DSKH)">
                    <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
                        <i class="la la-file-excel"></i> Xuất excel
                    </button>
                    <button v-else disabled
                            class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
                        Xuất excel
                    </button>
                </div>
                <!--                <router-link-->
                <!--                        class="font-weight-bold font-size-3  btn btn-success"-->
                <!--                        :to="{ name: 'user-create' }"-->
                <!--                >Thêm mới-->
                <!--                </router-link>-->
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <div class="row">
                    <div class="form-group col-md-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<svg xmlns="http://www.w3.org/2000/svg"width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24" height="24"></rect>
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                                                      fill="#000000" fill-rule="nonzero"
                                                                                      opacity="0.3"></path>
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                          fill="#000000" fill-rule="nonzero"></path>
																			</g>
																		</svg>
																	</span>
																</span>
                            </div>
                            <input type="text" class="form-control" placeholder="Nhập mã học viên,tên,SĐT,email"
                                   v-model="query.keyword"
                                   aria-describedby="basic-addon2">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                          <el-select v-model="query.finance_range" clearable filterable class="w-100" placeholder="Chọn tài chính">
                            <el-option
                                v-for="(item, key) in finances"
                                :label="item.name"
                                :value="item.value"
                                :key="key"
                            >
                              <span style="float: left">{{ item.name }}</span>
                            </el-option>
                          </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                          <el-select v-model="query.level" clearable filterable class="w-100" placeholder="Chọn level">
                            <el-option
                                v-for="(item, key) in levels"
                                :label="item.name"
                                :value="item.value"
                                :key="key"
                            >
                              <span style="float: left">{{ item.name }}</span>
                            </el-option>
                          </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                          <el-select v-model="query.branch_create" clearable filterable class="w-100" placeholder="Chọn văn phòng tạo">
                            <el-option
                                v-for="(item, key) in branches"
                                :label="item.name_alias ? item.name_alias : item.name"
                                :value="item.id"
                                :key="key"
                            >
                              <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                            </el-option>
                          </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                          <el-select v-model="query.branch_id" clearable filterable class="w-100" placeholder="Chọn văn phòng hẹn">
                            <el-option
                                v-for="(item, key) in branches"
                                :label="item.name"
                                :value="item.id"
                                :key="key"
                            >
                              <span style="float: left">{{ item.name }}</span>
                            </el-option>
                          </el-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <multiselect v-model="cacheTypeCustomer" :options="[]"
                                         placeholder="Chọn lọc danh sách khách hàng"
                                         label="position"
                                         track-by="position"
                                         :hideSelected="true"
                                         selectLabel=""
                                         @input="handleQueryLevel"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <date-picker
                                    v-model="query.ngay_hen"
                                    format="DD-MM-YYYY"
                                    valueType="YYYY-MM-DD"
                                    type="date"
                                    placeholder="Ngày hẹn"
                                    @input="handleNgayHen"
                            ></date-picker>
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <date-picker
                                    v-model="query.ngay_hen_range"
                                    format="DD-MM-YYYY"
                                    valueType="YYYY-MM-DD"
                                    type="date"
                                    range
                                    placeholder="Ngày tạo từ - Ngày tạo đến"
                                    @input="handleNgayHenRange"
                            ></date-picker>
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-between">
                            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                                    @click="searchData()">
                                <i class="fa fa-search"></i> Tìm kiếm
                            </button>
                            <button v-else type="button" disabled
                                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row mb-4 mt-4">
                    <div class="col-md-12">
                        <h4>Cột hiển thị</h4>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-3">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                                <input v-model=table_check_show.level type="checkbox" name="Checkboxes5"/>
                                <span></span>
                                Level
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                                <input v-model="table_check_show.user_create" type="checkbox" name="Checkboxes5"/>
                                <span></span>
                                Người tạo
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                                <input v-model="table_check_show.user_intro" type="checkbox" name="Checkboxes5"/>
                                <span></span>
                                Người giới thiệu
                            </label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                                <input v-model="table_check_show.nguoi_tu_van" type="checkbox" name="Checkboxes5"/>
                                <span></span>
                                Người tư vấn
                            </label>
                        </div>
                    </div>
                </div>
                <div class="example-preview table-responsive overflow-auto">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-30px">STT</th>
                            <th scope="col" class="min-w-100px">Mã học viên</th>
                            <th scope="col" class="min-w-100px">Họ và tên</th>
                            <th scope="col" class="min-w-100px">Số điện thoại</th>
                            <th scope="col" class="min-w-100px" v-if="table_check_show.level">Level</th>
                            <th scope="col" class="min-w-200px" v-if="table_check_show.user_create">Người tạo</th>
                            <th scope="col" class="min-w-200px" v-if="table_check_show.user_intro">Người giới thiệu</th>
                            <th scope="col" class="min-w-200px">Văn phòng tạo</th>
                            <th scope="col" class="min-w-100px">Ngày hẹn</th>
                            <th scope="col" class="min-w-200px">Văn phòng hẹn</th>
                            <th scope="col" class="min-w-200px" v-if="table_check_show.nguoi_tu_van">Người tư vấn</th>
                            <th scope="col" class="min-w-100px">Thời gian tạo</th>
                            <th scope="col" class="min-w-200px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in customers.data" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{parseInt(item.user_id) + 100000}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.phone}}</td>
                            <td v-if="table_check_show.level">{{item.level}}</td>
                            <td v-if="table_check_show.user_create">{{fillUser(item.get_sale)}}</td>
                            <td v-if="table_check_show.user_intro">{{fillUser(item.user_intro)}}</td>
                            <td>{{item.get_chi_nhanh_tao ? (item.get_chi_nhanh_tao.name_alias ? item.get_chi_nhanh_tao.name_alias : item.get_chi_nhanh_tao.name) : ''}}</td>
                            <td>{{item.ngay_hen | formatDate}}</td>
                            <td>{{item.get_chi_nhanh_hen ? item.get_chi_nhanh_hen.name : ''}}</td>
                            <td v-if="table_check_show.nguoi_tu_van">{{fillUser(item.get_tvv)}}</td>
                            <td>{{item.created_at | formatDate}}</td>
                            <td>
                                <router-link :to="{ name: 'customer-user-edit', params: {id : item.id} }"
                                             title="Xem chi tiết"
                                             v-if="currentUser.user.permission.includes(permission.CHI_TIET_DSKH)"
                                             class="btn btn-icon ml-2 btn-outline-info"><i class="far fa-eye"></i>
                                </router-link>
                              <router-link :to="{ name: 'customer-user-update', params: {id : item.id} }"
                                             title="Sửa khách hàng"
                                           v-if="currentUser.user.permission.includes(permission.SUA_KHACH_HANG)"
                                             class="btn btn-icon ml-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                                </router-link>
                                <router-link :to="{ name: 'customer-user-history', params: {id : item.id} }"
                                             title="Xem Lịch sử hoạt động"
                                             v-if="currentUser.user.permission.includes(permission.XEM_LICH_SU)"
                                             class="btn btn-icon ml-2 btn-outline-success"><i
                                        class="fas fa-history"></i>
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :page-class="'page-item'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
            >
            </paginate>
        </div>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="true"
                     :on-cancel="onCancel"
                     :is-full-page="fullPage"></loading>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

    import Multiselect from 'vue-multiselect'
    import FileUpload from 'vue-upload-component';
    // Date picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    //
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {
        EXPORT_CUSTOMER_GOOGLE_SHEET,
        EXPORT_EXCEL_CUSTOMER,
        GET_CUSTOMERS
    } from "../../../core/services/store/customer/customer.module";
    import {GET_FOLDERS_DRIVERS} from "../../../core/services/store/public/google.module";
    import {levels} from "@/core/option/customerOption";
    import {exportExcel} from "../../../core/filters";

    const _ = require('lodash');

    export default {
        components: {
            Multiselect, FileUpload, DatePicker, Loading
        },
        name: "CustomerIndex",
        data() {
            return {
                /*Export googlesheet*/

                popoverShow: false,
                isLoading: false,
                is_export_excel: false,
                is_disable_search: false,
                is_export_sheet: false,
                fullPage: true,
                folderData: '',
                path: '',
                validateState: null,
                options: [],
                /*End*/
                query: {
                    keyword: '',
                    ngay_hen: '',
                    ngay_hen_range: [],
                    dieu_kien_tai_chinh: [],
                    level: '',
                    branch_create: '',
                    branch_id: '', // văn phòng hẹn,
                    finance_range: []
                },
                table_check_show: {
                    level: true,
                    user_create: true,
                    user_intro: true,
                    nguoi_tu_van: true
                },
                branches: [],
                finances: [
                    {
                        value: [0, 5000000],
                        name: 'Từ 0 đến dưới 5 triệu',
                    },
                    {
                        value: [5000000, 10000000],
                        name: 'Từ 5 đến dưới 10 triệu',
                    },
                    {
                        value: [10000000, 20000000],
                        name: 'Từ 10 đến dưới 20 triệu',
                    },
                    {
                        value: [20000000, 30000000],
                        name: 'Từ 20 đến dưới 30 triệu',
                    },
                    {
                        value: [30000000, 40000000],
                        name: 'Từ 30 đến dưới 40 triệu',
                    },
                    {
                        value: [40000000, 50000000],
                        name: 'Từ 40 đến dưới 50 triệu',
                    },
                    {
                        value: [50000000, 1000000000],
                        name: 'Từ 50 triệu trở lên',
                    },
                ],
                levels: levels,
                customers: [],
                keyword: '',
                // Paginate
                page: 1,
                last_page: 1,
                status: {
                    0: 'Không hoạt động',
                    1: 'Hoạt động',
                    2: "Nghỉ việc"
                },
                status_search: [
                    [],
                    {
                        id: 0,
                        name: 'Không hoạt động'
                    }, {
                        id: 1,
                        name: 'Hoạt động'
                    }, {
                        id: 2,
                        name: 'Nghỉ việc'
                    },

                ],
                // Cache
                cacheFinance: [],
                cacheLevel: [],
                cacheBranchCreate: [],
                cacheBranchHen: [],
                cacheTypeCustomer: [], // Chọn lọc danh sách khách hàng
            }
        },
        watch: {
            folderData(val) {
                if (val) {
                    this.validateState = true;
                    this.path = val;
                }
            },
        },
        mounted() {
            this.pullParamsUrl();
            this.getFolders();
            this.getBranches();
            this.getList();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự", route: 'profile-1'},
                {title: "Danh sách khách hàng"}
            ]);
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },
        methods: {
            onCancel: function () {
                console.log('User cancelled the loader.')
            },
            onOk() {
                if (!this.folderData) {
                    this.validateState = false
                }
                if (this.folderData) {
                    this.onClose()
                    // Return our popover form results
                    this.exportGoogleSheet();
                }
            },
            onClose() {
                this.popoverShow = false
            },
            onShow() {
                this.folderData = ''
                this.validateState = null
            },
            onHidden() {
                this.focusRef(this.$refs.button)
            },
            getFolders: function () {
                this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
                    this.options = _.reduce(res.data, function (result, item, key) {
                        result.push({
                            text: item.name,
                            value: item.path
                        });
                        return result;
                    }, [])
                    this.options.unshift({text: '- Chọn thư mục -', value: ''});
                });

            },
            getList() {
                this.page = parseInt(this.$route.query.page)
                this.pullParamsUrl()
                this.callApiGetList()
            },
            searchData() {
                this.is_disable_search = true
                this.page = 1
                this.pushParamsUrl()
                this.callApiGetList()
            },

            pullParamsUrl() {
                this.query.keyword = this.$route.query.keyword
                this.query.finance_range = this.$route.query.finance_range
                this.query.level = this.$route.query.level
                this.query.branch_create = this.$route.query.branch_create
                this.query.branch_id = this.$route.query.branch_id
                this.query.ngay_hen = this.$route.query.ngay_hen
                this.query.ngay_hen_range = this.$route.query.ngay_hen_range
            },
            setCacheTheLoad() {
                // Finance cache
                let finance_query = this.query.finance_range
                if (finance_query != undefined && finance_query.length) {
                    let start_query = finance_query[0]
                    let end_query = finance_query[1]
                    let resFinnance = this.finances.find((item) => {
                        if (Array.isArray(item.value)) {
                            let start = item.value[0]
                            let end = item.value[1]
                            if (start == start_query && end == end_query) {
                                return item
                            }
                        }
                    })
                    this.query.finance_range = resFinnance.value
                }
                // Level cache
                let resLevel = this.levels.find((item) => {
                    if (item.value == this.query.level) {
                        return item
                    }
                })
                this.query.level = resLevel.value
                // Văn phòng tạo
                let resBranchCreate = this.branches.find((item) => {
                    if (item.id == this.query.branch_create) {
                        return item
                    }
                })
                this.query.branch_create = resBranchCreate.id
                // Văn phòng hẹn
                let resBranchHen = this.branches.find((item) => {
                    if (item.id == this.query.branch_id) {
                        return item
                    }
                })
                this.query.branch_id = resBranchHen.id
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({name: 'customer-user', query: {keyword: this.query.keyword, page: this.page}})
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_CUSTOMERS, {
                    keyword: this.query.keyword,
                    finance_range: this.query.finance_range,
                    level: this.query.level,
                    branch_create: this.query.branch_create,
                    branch_id: this.query.branch_id,
                    ngay_hen: this.query.ngay_hen,
                    ngay_hen_range: this.query.ngay_hen_range,
                    page: this.page,
                }).then((res) => {
                    this.customers = res.data
                    this.last_page = res.data.last_page
                    this.is_disable_search = false
                });
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'customer-user',
                    query: {
                        page: this.page,
                        keyword: this.query.keyword,
                        finance_range: this.query.finance_range,
                        level: this.query.level,
                        branch_create: this.query.branch_create,
                        branch_id: this.query.branch_id,
                        ngay_hen: this.query.ngay_hen,
                        ngay_hen_range: this.query.ngay_hen_range,
                    }
                })
            },
            exportExcel() {
                this.is_export_excel = true
                const HEADER_ROW_EXPORT_CUSTOMER_LIST = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'id',
                        value: 'Mã học viên',
                    },
                    {
                        key: 'name',
                        value: 'Họ và tên',
                    },
                    {
                        key: 'phone',
                        value: 'Số điện thoại',
                    },
                    {
                        key: 'level',
                        value: 'Level sale',
                    },
                    {
                        key: 'created_at',
                        value: 'Thời gian tạo',
                    },
                    {
                        key: 'ngay_hen',
                        value: 'Thời gian hẹn',
                    },
                    {
                        key: 'up_3b_time',
                        value: 'Thời gian lên 3B',
                    },
                    {
                        key: 'chi_nhanh_hen',
                        value: 'Văn phòng hẹn',
                    },
                    {
                        key: 'created_user',
                        value: 'Mã sale',
                    },
                    {
                        key: 'sale_name',
                        value: 'Tên sale',
                    },
                    {
                        key: 'sale_branch_id',
                        value: 'Mã văn phòng Sale',
                    },
                    {
                        key: 'sale_branch_name',
                        value: 'Tên văn phòng Sale',
                    },
                    {
                        key: 'sale_area_id',
                        value: 'Mã khu vực Sale',
                    },
                    {
                        key: 'sale_area_name',
                        value: 'Tên khu vực Sale',
                    },
                    {
                        key: 'tvv_id',
                        value: 'Mã tư vấn viên',
                    },
                    {
                        key: 'tvv_name',
                        value: 'Tên tư vấn viên',
                    },
                    {
                        key: 'tvv_branch_id',
                        value: 'Mã văn phòng TVV',
                    },
                    {
                        key: 'tvv_branch_name',
                        value: 'Tên văn phòng TVV',
                    },
                    {
                        key: 'tvv_area_id',
                        value: 'Mã khu vực TVV',
                    },
                    {
                        key: 'tvv_area_name',
                        value: 'Tên khu vực TVV',
                    },
                    {
                        key: 'hinh_thuc_tu_van',
                        value: 'Hình thức tư vấn',
                    },
                ]
                this.$store.dispatch(EXPORT_EXCEL_CUSTOMER, {
                    keyword: this.query.keyword,
                    finance_range: this.query.finance_range,
                    level: this.query.level,
                    branch_create: this.query.branch_create,
                    branch_id: this.query.branch_id,
                    ngay_hen: this.query.ngay_hen,
                    ngay_hen_range: this.query.ngay_hen_range,
                }).then((res) => {
                    this.is_export_excel = false
                    if (res.data.error) {
                        this.$bvToast.toast(res.data.message, {
                            title: 'Export excel',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        exportExcel(HEADER_ROW_EXPORT_CUSTOMER_LIST, res.data, "Danh sách khách hàng.xlsx", true)
                    }
                });
            },
            exportGoogleSheet() {
                this.isLoading = true
                this.$store.dispatch(EXPORT_CUSTOMER_GOOGLE_SHEET, {
                    path: this.path,
                    keyword: this.query.keyword,
                    finance_range: this.query.finance_range,
                    level: this.query.level,
                    branch_create: this.query.branch_create,
                    branch_id: this.query.branch_id,
                    ngay_hen: this.query.ngay_hen,
                    ngay_hen_range: this.query.ngay_hen_range,
                }).then((res) => {
                    this.isLoading = false
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Export google sheet',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Export google sheet',
                            variant: 'success',
                            solid: true
                        });
                    }
                }).catch((e) => {
                    if (e.data.error) {
                        this.$bvToast.toast(e.data.message, {
                            title: 'Export google sheet',
                            variant: 'danger',
                            solid: true
                        });
                    }
                });
            },

            handleNgayHen($event) {
                if (!$event) {
                    this.query.ngay_hen = ''
                }
            },
            handleNgayHenRange($event) {
                let start = $event[0]
                let end = $event[1]
                if (!start || !end) {
                    this.query.ngay_hen_range = []
                }
            },
            handleQueryLevel($event) {
                this.query.level = $event.value
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 100000,
                    is_with: 0,
                }).then((res) => {
                    if (!res.error) {
                        this.branches = res.data.data
                        this.setCacheTheLoad()
                    }
                });
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text


                })
            },
            fillUser(item) {
                if (!item) {
                    return '';
                } else {
                    let code = item.id + 100000
                    let name = item.name
                    return code + ' - ' + name;
                }

            },
        }
    }
</script>
<style>
    .fa-minus-circle:hover {
        color: red !important;

    }

    .input-group i {
        font-size: 0.9rem;
    }

    /*Css multiple select*/
    .mx-datepicker {
        width: 100% !important;
    }

    .multiselect__tags {
        padding: 3px 40px 0 9px !important;
        min-height: 38px !important;
    }

    .multiselect {
        min-height: 38px !important;
    }

    .mx-input {
        min-height: 38px !important;
    }

    .multiselect__single {
        margin-top: 5px !important;
    }
    .col-form-label{
      display: none;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
